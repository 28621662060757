import React from 'react';
import T from 'i18n-react';

import { UnsupportedBrowserBanner } from '@motorway/motorway-storybook-cra';

import { isIE } from 'Utilities/helpers';

import styles from './PageComposerIE11Banner.module.scss';

T.setTexts(require('Components/texts.json'));

const PageComposerIE11Banner = () => {
	const classNames = { container: styles.container, link: styles.link };
	return isIE() && <UnsupportedBrowserBanner classNames={classNames} linkUrl="/unsupported-banner" />;
};

export default PageComposerIE11Banner;
